<template>
  <ConfirmDialog
    :value.sync="show"
    :title="title"
    :positiveButtonText="buttonTitle"
    :positiveAction="generateRecommendations"
    negativeButtonText="Anuluj"
    :loading="loading"
    :disabledPositiveButton="loading || !recommendation"
    :loadingPositiveButton="loading"
    persistent
    dontHideOnPositiveAction
    negativeButtonColor="primary"
    :compact="false"
    showCloseBtn
  >
    <v-layout column slot="content">
      <RecommendationSelect
        label="Wybierz wizytę"
        :value.sync="recommendation"
        :patient="patient"
        :session="session"
        :owner="owner"
      ></RecommendationSelect>
      <DefaultPreview
        v-if="recommendation"
        :class="[this.mode !== 5 ? 'mb-6' : '']"
        label="Treść zalecenia:"
        :value="recommendationText"
        color="gray"
        html
      ></DefaultPreview>
    </v-layout>
  </ConfirmDialog>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import Validators from "@/mixins/Validators";
import PatientService from "@/services/patient.service";

export default {
  mixins: [Validators, ProxyCRUDMixin],
  timers: {
    checkRecommendations: { time: 1000 },
  },
  props: {
    value: {
      default: false,
    },
    mode: {
      type: String,
      default: "DOWNLOAD",
    },
    patient: {},
    session: {},
    owner: {},
  },
  data() {
    return {
      recommendation: null,
      text: null,
      generationRequestId: null,
    };
  },
  components: {
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    LabelTextField: () => import("@/components/LabelTextField"),
    LabelTextArea: () => import("@/components/LabelTextArea"),
    DefaultPreview: () => import("@/components/text/DefaultPreview"),
    RecommendationSelect: () =>
      import("@/components/selects/RecommendationSelect"),
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    recommendationText() {
      if (this.recommendation) {
        return this.recommendation.data.text;
      } else {
        return "Brak treści...";
      }
    },
    title() {
      if (this.mode === "DOWNLOAD") {
        return "Opcje pobierania";
      }
      return "Opcje drukowania";
    },
    buttonTitle() {
      if (this.mode === "DOWNLOAD") {
        return "Pobierz";
      }
      return "Drukuj";
    },
  },
  methods: {
    checkRecommendations() {
      PatientService.checkGeneratedRecommendationsStatus(
        this.generationRequestId
      )
        .then((reply) => {
          if (reply.status === true) {
            this.handleSuccess("Zalecenia zostały wygenerowane");
            this.$emit("action", reply.file);
            this.show = false;
          } else if (reply.status === false) {
            this.handleError("Coś poszło nie tak");
          } else {
            this.$timer.stop("checkRecommendations");
            this.$timer.start("checkRecommendations");
          }
        })
        .catch((error) => {
          console.log("ERROR", error);
          this.handleError("Coś poszło nie tak");
        });
    },
    generateRecommendations() {
      this.beforeRequest();
      PatientService.generateRecommendations(
        {
          recommendations: this.recommendation.id,
        },
        this.patient ? this.patient.id : undefined,
        this.owner ? this.owner.id : undefined
      )
        .then((reply) => {
          this.generationRequestId = reply.id;
          this.$timer.start("checkRecommendations");
        })
        .catch((error) => {
          console.log("ERROR", error);
          this.handleError("Coś poszło nie tak");
        });
    },
  },
};
</script>

<style scoped>
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>